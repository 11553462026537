@charset "UTF-8";
@import "./utils/variables";
@import "~bootstrap/scss/bootstrap";

body {
    //background-color: $red;
}

body, html {
  height:100%;
}

h1, h2, h3 {
    font-family: $f-secondary;
    line-height: 1.1;
    letter-spacing: 4px;

  .fa-circle {
    width: 10px!important;
  }
}

h4, h5 {
  font-family: $f-secondary;
  line-height: 1.1;
}

h2 {
    font-size: 2.75rem;
}

h4 {
  font-size: 1.25rem;
}

p {
    font-weight: 300;
    font-size: 16px;
}

.link-primary {
  color:$c-blue !important;
}

.btn-primary, .btn-secondary
 {
     border: none;
     border-radius: 20px;
     padding: 12px 25px;
     text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.2);
    transition: transform 0.2s ease-in-out;

    &:hover
    {
        box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.35);
        transform: translateY(-2px);
    }
 }


.btn-primary
{
    color: $c-blue-dark;
}

.btn-primary.bg-grey {
  background-color: $c-grey;

  &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
    background-color: $c-grey;
  }
}

.custom-control-label {
  &:before {
    background-color: #abaac9;
    border-color: #abaac9;
  }
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: $c-green;
  border-color: $c-blue-dark;
  background-color: $c-blue-dark;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#2DF2B5' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#2DF2B5'/%3e%3c/svg%3e");
}

main.main-wrapper {
  display:flex;
  flex-direction: column;
  min-height:100%;
  width:100%;
  overflow:hidden;
}

footer.page-footer {
  margin-top:auto;
}

.header
{
    .btn-primary, .btn-secondary
    {
        border-radius: 25px;
        padding: 8px 15px;
    }

    //transition:all 0.4s ease;

    &.active-toggler {
      &.toggled {
        #toggleHoverMessage {
          display:none;
        }
      }

      &:not(.toggled) {
        #navbarMainContainer {
          display:none;
        }
      }
    }

    &:not(.active-toggler) {
      #toggleHoverMessage {
        display:none;
      }
    }
}

.header_blue {
  .header {
    background-color: $c-blue-dark;
    .navbar-dark .navbar-nav

    {
      .nav-link, .nav-link:hover {
        color: white;
        text-transform: uppercase;
      }
    }
  }

}

.navbar-light .navbar-nav

{
    .nav-link {
        &:not(.btn-top-bar) {
            color: $c-blue-dark;
            text-transform: uppercase;
            /*font-size: 15px;
            font-weight: 500;
            text-align: center;
            /margin-left: 0.5rem;
            margin-right: 0.5rem;
            padding: 0px;
            transition: all 0.3s ease 0s;
            border-bottom: 2px solid transparent;

            &:hover {
                color:$c-blue-dark;
                padding-bottom:0.5rem;
                border-bottom:2px solid $c-blue-dark;
            }*/
        }
    }
}

.footer {
  a {
    text-transform: uppercase;
    color: white;
  }
  .footer-copyright {
    font-style: italic;
  }
}

.shape-right-purple {
  &:after {
    content: "";
    position: absolute;
    top: 1px;
    right: -1px;
    background-image: url("../img/shape-purple.svg");
    transform: translateY(-100%);
    width: 185px;
    height: 185px;
  }
}

.shape-left-grey {
  &:after {
    content: "";
    position: absolute;
    top: 1px;;
    left: 0;
    background-image: url("../img/shape-grey.svg");
    transform: translateY(-100%);
    width: 185px;
    height: 185px;
  }
}
.shape-right-grey {
  &:after {
    content: "";
    position: absolute;
    top: 1px;;
    right: -1px;
    background-image: url("../img/shape-grey.svg");
    transform: translateY(-100%) rotate(-90deg);
    width: 185px;
    height: 185px;
  }
}

.shape-right-white {
  overflow-x:hidden;
  width:100%;
  &:after {
    content: "";
    position: absolute;
    top: 1px;
    right: -1px;
    background-image: url("../img/shape-white.svg");
    transform: translateY(-100%);
    width: 185px;
    height: 185px;
  }

  &-light {
    &:after {
      background-image: url("../img/shape-white-light.svg");
      transform: translateY(0);
      top: -1px;
    }
  }
}

.menu-sidebar {
  a {
    color: $c-blue-dark;
    text-transform: uppercase;
    position: relative;
    border-bottom: 4px solid $c-grey;

    &.selected {
      color:$c-blue;
    }

    &:before {
      content: '>';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-20%);
      transition:0.4s all ease;
    }

    &:hover {
      &:before {
        left:8px;
        margin-right:-8px;
      }
    }
  }
}

.menu-footer
{
  a {
    color: $c-blue-dark;
    text-transform: uppercase;
    position: relative;
    font-size: 0.9rem;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-20%);
    }

  }
}

.content-module
{
  .table thead th {
    vertical-align: top;
  }

  overflow: hidden;
  .bandeau-image
  {
    width:100%;
    height: 120px;
    object-fit: cover;
    border-radius: 3px;
  }
  .corps-module {
    border-radius: 3px;
    background-color: $c-grey;
    min-height: 500px;
    position: relative;
    //overflow:hidden; // @TODO checker en version mobile si tout est ok?

    p {
      strong {
        font-weight:bold;
      }
    }

    .explication-container {
      div, span {
        width:100%;
      }
    }

    .pagination
    {
      display: block;
      background-color: $c-blue;
      color: white;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2);
    }

    .titre-etape {
      font-size: 1.75rem;
      font-family: $f-primary;
      color: $c-blue;
      font-weight: 700;
      letter-spacing: 1px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 12%;
        height: 4px;
        background-color: $c-green;

        @media (max-width: 575px) {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .question-etape
    {
      font-family: $f-primary;
      font-weight: 400;
    }

    .questions
    {
      font-size: 0.9rem;
    }

    blockquote {
      display:block;
      width:100%;
      padding: 14px 16px;
      padding-bottom:6px;
      background:rgba(24, 12, 80, 0.06);
      border-left:12px solid rgba(24, 12, 80, 0.1);
      margin-top:12px;
      margin-bottom:12px;

      &::before {
        display:inline-block;
        width:100%;
        font-size:12px;
        font-weight:bold;
      }
    }
  }

  .cadre_valider {
    background-color: $c-blue-dark;
    color: white;
    font-size: 0.95rem;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;

    .btn {
      border-radius: 25px;
      padding: 8px 18px;
    }
  }
  .cadre_temps
  {
    font-size: 0.9rem;
  }


  .card-aide
  {
    position: absolute;
    right: 8px;
    top: 5px;
    left: 8px;
    max-height: calc(100% - 18px);
    background-color: white;
    border-radius: 10px;
    z-index: 999;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2);
    transform: translateX(100%);
    opacity: 0;
    transition: all 0.5s ease-in-out;
    width: calc(100% - 16px);
    overflow-x: hidden;

    &.active {
      transform: translateX(0);
      opacity: 1!important;
      overflow-y: scroll;
    }

    &-text {
      img {
        max-width: 100%!important;
        height: auto!important;
      }
      table {
        width: 100%!important;
      }
    }
  }
}

.chronos {
  width: 100px;
  //background-color: white;
}
.container_timer {
  width: 100px;
}

$background: white;
$duration: 60s;

.radial-timer {
  // CONFIG
  $size: 50px;
  $thickness: 100px;
  $color: $c-green;

  position: absolute;
  top: 58px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 50%;

  overflow: hidden;
  height: $size;
  width: $size;

  .radial-timer-half {
    height: $size;
    width: $size/2;
    border-radius: $size/2 0 0 $size/2;
    background: $color;
    position: absolute;
    &:nth-of-type(2) {
      z-index: 99999999;
      transform: rotate(180deg);
      transform-origin: center right;
    }

    &:before {
      content: "";
      position: absolute;
      top: $thickness;
      left: $thickness;
      height: $size - 2*$thickness;
      width: ($size - 2*$thickness)/2;
      border-radius: ($size - $thickness)/2 0 0 ($size - $thickness)/2;
      background: $background;
    }
    &:after {
      content: "";
      position: absolute;
      background: $background;
      height: $size*2;
      width: $size*1.5;
      left: -$size;
      top: -$size/2;
      -webkit-transform-origin: center right;
    }
  }

  &.s-animate {
    -webkit-transform-origin: center right;
    .radial-timer-half:nth-of-type(1):after {
      -webkit-animation: rotateLeftMask $duration infinite linear;
    }
    .radial-timer-half:nth-of-type(2):after {
      -webkit-animation: rotateRightMask $duration infinite linear;
    }
  }
}

// Animations

@-webkit-keyframes rotateLeftMask {
  0%   { -webkit-transform: rotate(0deg); }
  50%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(180deg); }
}
@-webkit-keyframes rotateRightMask {
  0%   { -webkit-transform: rotate(0deg); }
  50%   { -webkit-transform: rotate(180deg); visibility: hidden; }
  100% { -webkit-transform: rotate(180deg); visibility: hidden; }
}

.aiguille {
  position: absolute;
  top: 33.5px;
  left: 50%;
  width: 8px;
  height: 100px;
  transform: translate(-35%, 0);
  animation: round $duration linear infinite ;
}

.timer {
  position: absolute;
  top: 50%;
  left: 0;
  width: 8px;
  fill:$c-blue-dark;
  transform-origin: bottom left;
  transform: translate(0, -85%);
}

@keyframes round {
  from { transform: translate(-35%, 0) rotate(0deg); }
  to   { transform: translate(-35%, 0) rotate(360deg); }
}

.alert {
  border-right:0;
  border-top:0;
  border-bottom:0;
  border-left-width: 10px;
  border-radius:0;

  .close {
    text-shadow:none;
  }
}

.titre-sequence {
  font-size:22px;
  letter-spacing: 2px;
  color: $c-blue-dark;

  &:hover {
    color: $c-blue-dark;
  }

  .moreless {
    font-size: 11px;
    color: $c-blue!important;
    text-decoration: underline;
    text-transform: lowercase;
    font-family: $f-primary;
    font-style: italic;
    letter-spacing: 0px;
  }
}

.label-sequence {
  text-transform: uppercase;
  color: $c-blue;
  font-size: 14px;

  i, svg {
    font-size: 9px;
  }
}

.StepProgress {
  position: relative;
  padding-left: 45px;
  list-style: none;

  &::before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
    border-left: 2px solid $c-gris-progress;
  }

  &-item {
    position: relative;
    counter-increment: list;

    &:not(:last-child) {
      padding-bottom: 20px;
    }

    &::before {
      display: inline-block;
      content: '';
      position: absolute;
      left: -30px;
      height: 100%;
      width: 10px;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      left: -40px;
      width: 24px;
      height: 24px;
      border: 2px solid $c-gris-progress;
      border-radius: 50%;
      background-color: #FFF;
    }

    &.is-done {
      &::before {
        border-left: 2px solid $c-blue-dark;
      }
      &::after {
        content: "✔";
        font-size: 10px;
        color: $c-green;
        line-height:20px;
        text-align: center;
        border: 2px solid $c-blue-dark;
        background-color: $c-blue-dark;
      }
    }

    &.current {
      &::before {
        border-left: 2px solid $c-blue-dark;
      }

      &::after {
        content: counter(list);
        padding-top: 1px;
        top: 0;
        left: -40px;
        width: 24px;
        height: 24px;
        font-size: 14px;
        line-height:18px;
        text-align: center;
        color: $c-blue-dark;
        border: 2px solid $c-blue-dark;
        background-color: white;
      }
    }
  }

  strong {
    display: block;
  }
}

label {
  &.optional {
    &::after {
      color:$c-txt-muted;
      content:' (Optionel)';
    }
  }

  &:not(.no-asterisk).required {
    &::before {
      content:'*';
      color:$c-red;
      margin-right:3px;
      display:inline-block;
    }
  }
}

.modal-dialog {
  .modal-content {
    border:0px;
  }
}

input {
  &::placeholder {
    font-weight:300;
  }
}

.forgot-password-link {
  color:$c-blue-dark !important;
  text-decoration:underline;

  &:hover {
    text-decoration: none;
  }
}

.contenu-peda-etape {
  ul {
    li {
      font-size: 1rem;
      font-weight: 300;
    }
  }
}

.contenu-peda-etape ul.emplacement-etiquette li {
  font-weight:500;
}

ul.echelle-reponses {
 list-style-type: none;
 display:flex;
 align-items:flex-start;
 justify-content:flex-start;
 padding-left:0;

 li {
   background:rgba(24, 12, 80, 0.06);
   height:80px;
   display:inline-block;
   margin: 0 3px;
   padding: 10px 0px 10px 14px;
   min-width: 80px;
   width: 100%;
   display:flex;
   align-items:center;
   font-size:12px !important;
   position:relative;
   font-family: "Roboto", sans-serif;
   font-weight:700;
   font-size:1.25em;

   .radio {
     width:100%;

   }

   .custom-control-label::before {
     top:2px;
   }

   .radio label {
    padding-left:0 !important;
    margin-bottom:0 !important;

    input {
      margin-right:8px;
    }
   }

   &:first-child {
     margin-left:0;
   }

   &:last-child {
     margin-right:0;

     &:after, &:before {
       display:none;
     }
   }

   &:after {
    content: " ";
    position: absolute;
    top: 0;
    right: -20px;
    width: 0;
    height: 0;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-left: 20px solid rgba(24, 12, 80, 0.06);
    z-index: 2;
   }

   &:before {
    content: " ";
    position: absolute;
    top: 0;
    right: -26px;
    width: 0;
    height: 0;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-left: 20px solid #ebeffd;
    z-index: 2;
   }

   &.selected-step {
     background:#1233FB;

     label {
       color:white !important;
     }

     &:after {
       border-left: 20px solid #1233FB;
     }
   }
 }
}

.emplacement-etiquette {
  letter-spacing: 0px !important;

  &.target {
    border: 3px solid #FC427B;

    li {
      border-radius: 0;
      min-height:29px;
      font-weight:500;
    }
  }

  li {
    &.not-posed {
      display: none;
      margin:0;
      padding:0;
    }

    cursor: grab;
    margin: 0px;
    padding: 4px 6px;
    font-size: 14px;
    min-width: 120px;
    min-height: 35px;
    display: flex;
    align-items: center;
    font-weight:500;
    background-color:#FC427B;
  }

  list-style-type: none;
  margin: 1px 4px;
  padding: 0px;
  min-width: 120px;
  min-height: 35px;
  display: inline-block;
  color: #fff;
  border: 3px dashed #a7acbf;
  background-color: #f7f7f7;
  border-radius: 0.2rem;
  vertical-align: middle;
}

#tag-bag {
  border: 3px dashed #a7acbf;
  padding: 20px;
  margin: 5px;
  background: #f7f7f7;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  li {
    cursor: grab;
    padding: 4px 6px;
    font-size: 16px !important;
    min-width: 120px;
    min-height: 36px;
    display: flex;
    align-items: center;
    font-weight: 500 !important;
    margin: 5px;
    z-index:1011 !important;

    &.not-posed {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.badge.badge-danger {
  background-color:#FC427B !important;
}

kbd {
  line-height: 30px;
  font-size: 15px;
}

.une-sequence {
  .card {
    border-radius: 8px;
    border: 0;
    background: $c-grey;
    transition: all ease .5s;

    &:hover {
      *:not(.btn):not(.text-info-fin):not(.badge):not(.badge-score) {
        color: white!important;
      }
      background: $c-blue;

      .btn {
        &:not(.bypass-hover) {
          color: $c-blue-dark;
          background-color: $c-green;
          border-color: $c-green;
        }
      }
    }

    &-body-titre {
      position: relative;

      @media (min-width: 768px) {
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 20px;
          width: 2px;
          height: 60px;
          background: white;
          border-radius: 20px;
          transform: translateY(-50%);
        }
      }

    }
  }

  &.en-cours {
    .card {
      background: $c-blue-dark;
      color: white!important;
      *:not(.btn):not(.text-info-fin):not(.badge):not(.badge-score) {
        color: white!important;
      }
    }
  }

  .btn {
    border-radius: 28px;
  }

  .text-info-fin {
    font-size: 12px;
    color: $c-blue-dark;
    background: white;
    border-radius: 2px;
    padding: 2px 5px;
    font-weight: bold;
  }

  .badge {
    color: $c-blue-dark;
    background: white;
    border-radius: 2px;
    padding: 2px 5px;
    font-style: italic;

    .badge-score {
      color: $c-blue;
    }
  }

  .text-time {
    color: $c-blue-dark;

    i, svg {
      color: $c-blue;
    }
  }
}

.bouton-play {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%,50%)!important;
  display: flex;
  align-items: center;

  i {
    font-size: 20px;
  }
}

.faq-bloc {

  .card {
    border: 0;
    border-radius: 20px;

    &.active {
      background: $c-blue-dark;

      .card-header {
        background: transparent;
      }

      .btn-link {
        color: white;
      }
    }
  }

  .card-header {
    padding: 0;
    background: $c-grey;
    border: 0;
    border-radius: 20px;
    h5 {
      margin: 0;
    }
    .btn {
      color: $c-blue-dark;
      font-size: 20px;
      text-decoration: none;
      letter-spacing: 1px;
    }
  }
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background: $c-blue;
  }
  a {
    color: $c-blue;
  }
  .btn-link {
    color: $c-blue;
  }

  .collapse.show {
    background: transparent;
    color: white;
    border-radius: 20px;
  }
}

.titre-sequence-liste {
  font-size: 24px;
  text-transform: uppercase;
  color: $c-blue-dark;
  letter-spacing: 4px;
}

#toggleHoverMessage {
  color:white;
  font-size:12px;
  padding:8px 0;
}

.txt-white {
  color:white !important;
}

.page-contenu {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h2, h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1rem;
      height: 1px;
      background: $c-blue;
      width: 100px;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    margin-left: 1rem;
    color: black
  }
  p {
    color: black;
    font-weight: 400;
  }
  a {
    color: $c-blue;
  }

  .container-bloc-illu {
      .bloc-illu {
        background: white;
        border: 1px solid $c-grey;
        border-radius: 8px;

        h4 {
          letter-spacing: 4px;
          color: $c-blue;
        }
        p {
          margin: 0;
        }
      }
  }
}

#form_filters {

  input[type=search] {
    background: $c-grey;
    color: $c-blue-dark;
    border-radius: 0.5rem;

    &::placeholder {
      color: $c-blue-dark;
    }
  }

  select {
    background: $c-grey;
    color: $c-blue-dark;
    border-radius: 0.5rem;
  }

  button[type="submit"] {
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    padding: 10px 14px;

    &:hover {
      transform: none;
    }

    i, svg {
      font-size: 20px;
    }
  }

  .thematique {
    .card {
      border:0;
      background: $c-grey;
      color: $c-blue-dark;
      text-transform: uppercase;
      border-radius: 0.5rem;
    }
    .card-header {
      padding: 0;
      border: 0;
      background-color: transparent;

      button {
        color: $c-blue-dark;
        text-transform: uppercase;
        font-weight: 100;
        text-decoration: none;
      }
    }
    .card-body {
      label {
        color: $c-blue-dark;

        &:hover {
          color: $c-blue;
          cursor: pointer;
        }
      }
      .form-check {
        position:relative;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left:0;
          width: 95%;
          height: 4px;
          background: white;
        }

        .custom-control-label::after {
          border: 2px solid $c-green;
          width: 0.8rem;
          height: 0.8rem;
          background: transparent;
          top: 0.25rem;
          left: -1.25rem;
        }

        .custom-control-label:before {
          background: transparent;
          border: 0;
          top: 0.25rem;
          left: -1.25rem;
          width: 0.8rem;
          height: 0.8rem;
        }

        .custom-control-input:checked ~ .custom-control-label::before {
          background: $c-blue;
        }
      }
    }
  }
}

.module-preview, .module-online {
    .ui-tooltip {
        background: white;
        box-shadow: 0 0px 3px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        margin-top:-12px;
    }
}

.fond-yt {
  background-image: url("../img/fond-yt.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  iframe {
    border-radius: 10px;
    position: relative;

    @media (max-width: 575px) {
      height: 250px;
    }

    &-after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $c-blue-dark;
      transform: translate(10px, 10px);
    }
  }
}
