@font-face {
  font-family: 'Piximisa';
  src: url('../fonts/piximisa/PiximisaRegular.eot');
  src: url('../fonts/piximisa/PiximisaRegular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/piximisa/PiximisaRegular.woff2') format('woff2'),
  url('../fonts/piximisa/PiximisaRegular.woff') format('woff'),
  url('../fonts/piximisa/PiximisaRegular.ttf') format('truetype'),
  url('../fonts/piximisa/PiximisaRegular.svg#PiximisaRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}


//FONTS
$f-primary : 'Roboto', sans-serif;
$f-secondary : 'Piximisa';


//COLORS
$c-green : #2DF2B5;
$c-blue : #1233F9;
$c-grey : #ebeffd;
$c-blue-light : #9DB1F6;
$c-blue-dark : #180C50;
$c-gris-progress: #d1d6e8;
$c-red: #FC427B;
$c-txt-muted: #6c757d;

//OVERRIDE
$primary : $c-green;
$secondary: $c-blue;
$body-color : $c-blue-dark;
$font-family-base : $f-primary;

$red: $c-red;

.text-blue {
  color: $c-blue;
}
.text-blue-dark {
  color: $c-blue-dark;
}
.text-green {
  color: $c-green;
}

.bg-blue {
  background-color: $c-blue;
}
.bg-blue-light {
  background-color: $c-blue-light;
}
.bg-blue-dark {
  background-color: $c-blue-dark;
}
.bg-grey {
  background-color: $c-grey;
}
